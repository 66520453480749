<template>
    <div class="wrap">
        <section class="banner"></section>
        <section class="news">
            <div class="content-container">
                <div class="tabs-wrap">
                    <div class="tabs" v-if="newsTabs.length">
                        <span v-for="tab in newsTabs" :class="[selectTabId === tab.id ? 'on' : '']" :key="tab.id" @click="toogleTab(tab.id)">{{tab.name}}</span>
                    </div>
                </div>
                <div class="list">
                    <div class="item" v-for="item in newsList" :key="item.id" @click="goNewsDetail(item.id)">
                        <div class="info">
                            <div class="title">{{item.title}}</div>
                            <div class="content line-clamp">{{item.content}}</div>
                            <div class="time">{{item.publish_at}}</div>
                        </div>
                        <el-image class="cover" :src="item.cover || defaultImg" lazy style="width: 148px; height: 104px;" fit="cover">
                            <template #placeholder>
                                <div class="image-slot">
                                    <img :src="defaultImg" alt="" width="148" height="104"  />
                                </div>
                            </template>
                            <template #error>
                                <div class="image-slot">
                                    <img :src="defaultImg" alt="" width="148" height="104"  />
                                </div>
                            </template>
                        </el-image>
                    </div>
                </div>
                <el-pagination
                    class="my-pagination"
                    v-model:currentPage="pageNum"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </section>
    </div>
</template>
<script>
import {
    getCurrentInstance,
    reactive,
    toRefs,
    inject,
    watchEffect,
    computed
} from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
export default {
    name: 'news',
    setup() {
        const { proxy } = getCurrentInstance()
        const store = useStore()
        const router = useRouter()
        const state = reactive({
            newsList: [],
            pageSize: 0,
            pageNum: 1,
            total: 0,
            defaultImg: require('../../assets/image/notice-image.png')
        })

        const selectTabId = computed(() => {
            const { newsTabId } = store.state
            if(!isNaN(newsTabId)) getList(newsTabId)
            return newsTabId
        })

        const newsTabs = inject('newsTabs')

        watchEffect(() => {
            if(!newsTabs?.value?.length) return
            if(!isNaN(selectTabId.value)) return
            store.commit("setNewsTabId", newsTabs.value[0].id)
        })


        const toogleTab = (id) => {
            store.commit("setNewsTabId", id)
            getList(id)
        }

        
        const goNewsDetail = (id) => {
            router.push({path: '/newsCenter/newsDetail', query: { id }})
        }

       
        function getList(id) {
            proxy.$api.article.getList(id, state.pageNum).then(res => {
                state.newsList = res.data && res.data.length ? res.data : []
                state.total = res.total
                state.pageSize = res.pageSize
            })
        }

        return {
            ...toRefs(state),
            selectTabId,
            newsTabs,
            toogleTab,
            goNewsDetail
        }
    }
}
</script>
<style lang="scss" scoped>
    @import "index";
</style>